import React from "react";
import ReactDOM from "react-dom";
import "./styles/App.scss";
import App from "./App";
import { LunaIconSpriteProvider } from './components';

ReactDOM.render(
  <React.StrictMode>
    <LunaIconSpriteProvider>
      <App />
    </LunaIconSpriteProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
