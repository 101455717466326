
import React from 'react';

import {
    LunaContainer, LunaContainerThemes, LunaContainerPaddings, LunaSystemMessage,
    LunaSystemMessageVariants,
    LunaSystemMessageTypes
} from './'

const messages = {
    REGISTRATION_HEADER: 'Registration Incomplete',
    REGISTRATION_INCOMPLETE: 'Your registration request cannot be completed at this time.',
    PLEASE_CONTACT: 'If you have questions, please contact us at 1-800-342-4820, 6:00 AM to 6:00 PM, Central Standard Time, Monday through Friday.'
}

export default function RegistrationIncomplete() {
    return (
        <LunaContainer
            theme={LunaContainerThemes.white}
            padding={[LunaContainerPaddings.l20.vertical, LunaContainerPaddings.l10.horizontal]}
            className="luna-container-card"
        >
            <LunaSystemMessage
                variant={LunaSystemMessageVariants.inline}
                type={LunaSystemMessageTypes.critical}
                headerText={messages.REGISTRATION_HEADER}
            >
                <p className="luna-type-body-30">{messages.REGISTRATION_INCOMPLETE}</p>
                <p className="luna-type-body-30">{messages.PLEASE_CONTACT}</p>
            </LunaSystemMessage>

        </LunaContainer>
    )
}
