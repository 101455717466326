
import React from 'react';

import {
    LunaContainer, LunaContainerThemes, LunaContainerPaddings, LunaSystemMessage,
    LunaSystemMessageVariants,
    LunaSystemMessageTypes
} from './'

const messages = {
    REGISTRATION_HEADER: 'Registration Successful',
    REGISTRATION_COMPLETED: 'Your registration has been completed successfully.',
    USERNAME: 'Your username will be:',
    LOGIN_INSTRUCTIONS: 'You will receive an email with login instructions.',
    PLEASE_CONTACT: 'If you have questions, please contact us at 1-800-342-4820, 6:00 AM to 6:00 PM, Central Standard Time, Monday through Friday.'
}


export default function RegistrationSuccessful(props) {
    const { candidate } = props;
    const { generatedEmail } = candidate || {};
    return (
        <LunaContainer
            theme={LunaContainerThemes.white}
            padding={[LunaContainerPaddings.l20.vertical, LunaContainerPaddings.l10.horizontal]}
            className="luna-container-card"
        >
            <LunaSystemMessage
                variant={LunaSystemMessageVariants.inline}
                type={LunaSystemMessageTypes.success}
                headerText={messages.REGISTRATION_HEADER}
            >
                <p className="luna-type-body-30">{messages.REGISTRATION_COMPLETED}</p>
                <p className="luna-type-body-30">{messages.USERNAME} {generatedEmail}</p>
                <p className="luna-type-body-30">{messages.LOGIN_INSTRUCTIONS}</p>
                <p className="luna-type-body-30">{messages.PLEASE_CONTACT}</p>
            </LunaSystemMessage>

        </LunaContainer>
    )
}