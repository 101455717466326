import { useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import {
  UserRegistrationForm,
  PasswordConfirmationForm,
  RegistrationSuccessful,
  RegistrationIncomplete,
  Header,
  Footer,
  Loader,
  ContentSecurityPolicy
} from './components'

import "./styles/App.scss";

export const REGISTRATION_STATE = {
  INCOMPLETE: 'INCOMPLETE',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE'
}

export const RESPONSE_CODES = {
  SUCCESS: 'S',
  FAILURE: 'F'
}

function App() {
  const [loaderState, setLoaderState] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const [registrationState, setRegistrationState] = useState("");
  const [candidate, setCandidate] = useState({})
  const [responseError, setResponseError] = useState('')

  const validCandidate = candidate && Object.keys(candidate).length > 0 && candidate.constructor === Object;


  function showLoader(state) {
    setLoaderState(state);
  }

  function renderMain() {
    const showUserRegistration = !loaderState && !validCandidate && !registrationState;
    const showPasswordConfirmation = !loaderState && validCandidate && (!registrationState || registrationState === REGISTRATION_STATE.FAILED) && candidate.processStatus !== REGISTRATION_STATE.COMPLETE;
    const showRegistrationSuccessful = !loaderState && (registrationState === REGISTRATION_STATE.COMPLETE || candidate.processStatus === REGISTRATION_STATE.COMPLETE);
    const showRegistrationIncomplete = !loaderState && registrationState === REGISTRATION_STATE.INCOMPLETE;

    return <>
      {showUserRegistration ? <UserRegistrationForm showLoader={showLoader} setCandidate={setCandidate} setLoaderMessage={setLoaderMessage} setResponseError={setResponseError} responseError={responseError} /> : null}
      {loaderState ? <Loader message={loaderMessage} /> : null}
      {showPasswordConfirmation ? <PasswordConfirmationForm showLoader={showLoader} setCandidate={setCandidate} setLoaderMessage={setLoaderMessage} setRegistrationState={setRegistrationState} candidate={candidate} setResponseError={setResponseError} responseError={responseError} /> : null}
      {showRegistrationSuccessful ? <RegistrationSuccessful candidate={candidate} /> : null}
      {showRegistrationIncomplete ? <RegistrationIncomplete /> : null}
    </>
  }


  return (

    <div className="App">
      <ContentSecurityPolicy />
      <Header />

      <main className="App-container">

        <Router>
          <Switch>
            <Route exact path="/" component={() => renderMain()} />
            <Route path="/frRegistrationEmail.do" component={() => renderMain()} />
            <Route path="/afrRegistrationEmail.do" component={() => renderMain()} />
            <Route path="/staffRegistrationEmail.do" component={() => renderMain()} />
            <Route component={RegistrationIncomplete} />
          </Switch>
        </Router>

      </main>

      <Footer />
    </div >
  );


}

export default App;
