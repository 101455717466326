import React from 'react';
import { Helmet } from "react-helmet";
import { lambdaProxyUrl } from '../config'

const getConnectSrc = () => {
    const { REACT_APP_ENV, NODE_ENV } = process.env;
    const env = REACT_APP_ENV || NODE_ENV;
    
    switch (env) {
        case 'development':
            return `ws://localhost:3000 http://localhost:3000 http://localhost:8080 ${lambdaProxyUrl}`;
        case 'int':
            return `https://ssrint.iampnon.apps.northwesternmutual.com https://ssrtest.northwesternmutual.com ${lambdaProxyUrl}`;
        case 'qa':
            return `https://ssrqa.iampnon.apps.northwesternmutual.com https://ssrstage.northwesternmutual.com ${lambdaProxyUrl}`;
        case 'prod': 
            return `https://ssr.iamp.apps.northwesternmutual.com https://ssr.northwesternmutual.com ${lambdaProxyUrl}`;
        default:
            return '';
    }
}

const getScriptSrc = () => {
    const { REACT_APP_ENV, NODE_ENV } = process.env;
    const env = REACT_APP_ENV || NODE_ENV;

    switch (env) {
        case 'development':
            return '\'unsafe-inline\' \'self\' *.ctctcdn.com cdnjs.cloudflare.com www.google.com https://www.google.com/recaptcha/ https://www.gstatic.com/recaptcha/';
        case 'int':
        case 'qa':
        case 'prod':
            return '\'self\' *.ctctcdn.com cdnjs.cloudflare.com www.google.com https://www.google.com/recaptcha/ https://www.gstatic.com/recaptcha/';
        default:
            return '';
    }
}

export default function ContentSecurityPolicy() {
    const connectSrc = getConnectSrc();
    const scriptSrc = getScriptSrc();

    const metaContent = `connect-src ${connectSrc}; 
    default-src 'self' northwesternmutual.com *.northwesternmutual.com;
    script-src ${scriptSrc};
    style-src 'self' 'unsafe-inline' *.ctctcdn.com *.northwesternmutual.com;
    img-src 'self';
    object-src 'self';
    frame-src www.google.com recaptcha.google.com;`;

    return (
        <Helmet>
            <meta http-equiv="Content-Security-Policy" content={metaContent} />
        </Helmet>
    )
}
