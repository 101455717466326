export function validateEmail(email) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
}

export function validatePassword(password) {
    const regex = /^((?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])|(?=.*[a-z])(?=.*[0-9])(?=.*[@#$%^&*\-_!+=\][{}|:',~/.?`\\"();><\s])|(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&*\-_!+=\][{}|:',~/.?`\\"();><\s])|(?=.*[A-Z])(?=.*[a-z])(?=.*[@#$%^&*\-_!+=\][{}|:',~/.?`\\"();><\s])).{15,64}$/;
    const notRequiredCharRegex = /^(?=.*[^A-Za-z0-9@#$%^&*\-_!+=[\]{}|\\:',.?/`~"();<>\s]).+$/;
    return !notRequiredCharRegex.test(password) && regex.test(password);
}

export function validateName(name) {
    const regex = /^(?=.{1,})[A-Za-z]+([ '-][A-Za-z]+)*$/;
    return regex.test(name);
}
