
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';

import {
    LunaContainer, LunaContainerThemes, LunaContainerPaddings,
    LunaFormControlGroup,
    LunaInput,
    LunaButton,
    LunaSectionHeader,
    LunaSectionHeaderDividers,
    LunaSectionHeaderMargins,
    LunaSectionHeaderTitle,
    LunaSystemMessage,
    LunaSystemMessageVariants,
    LunaSystemMessageTypes
} from './'

import { validatePassword, isEmptyObject } from '../utils';
import { REGISTRATION_STATE, RESPONSE_CODES } from '../App';
import { fetchRegisterLambda, fetchStatusLambda } from '../services';

const messages = {
    PASSWORD_CONFIRMATION: 'Password Confirmation',
    CONTACT_INFORMATION: 'Contact Information',
    HELLO: 'Hello',
    CONTACT_INFO_BODY_1: 'Your registered email address is',
    CONTACT_INFO_BODY_2: 'If above information is incorrect, do not complete the registration and contact Customer Service at 1-800-342-4820, 6:00 AM to 6:00 PM, Central Standard Time, Monday through Friday.',
    LOGIN_INFORMATION: 'Login Information',
    LOGIN_INFO_BODY: 'Your Northwestern Mutual Username will be created automatically and sent to your email address.',
    CREATE_PASSWORD: 'Create Password',
    NEW_PASSWORD: 'New Password',
    NEW_PASSWORD_HELP: 'Password is case sensitive. It must be 15-64 characters in length and contain 3 of the 4 following types of characters: lower case letter, upper case letter, numeral, following special character @ # $ % ^ & * - _ ! + = [ ] { } | \\ : \' , . ? / ` ~ " ( ) ; < > and spaces.',
    NEW_PASSWORD_ERROR: 'Password does not meet the password policy.',
    CONFIRM_NEW_PASSWORD: 'Confirm New Password',
    CONFIRM_NEW_PASSWORD_ERROR: 'Your Password and Confirm Password do not match.',
    SUBMIT: 'Submit',
    LOADER_MESSAGE: 'Creating your account. This will take 1-2 minutes. Please be patient and avoid refreshing the page or using your browser\'s back button.',
    INVALID_INPUT_ERROR: "Please verify inputs",
    STATUS_RETRIES_ERROR: 'Status retries error'

}

//const PASSWORD_MAX_LENGTH = 64;
const MINUTES_TO_WAIT = 3;// 2 minutes 
const MAX_WAIT_FOR_ACCOUNT_CREATION = MINUTES_TO_WAIT * 60 * 1000; //In milliseconds 
const TIME_BETWEEN_STATUS_CALLS = 5000;
const MAX_STATUS_RETRIES = MAX_WAIT_FOR_ACCOUNT_CREATION / TIME_BETWEEN_STATUS_CALLS;
let statusRetries = 0;


const renderInvalidInputMessage = (message) => {
    return message ? <LunaSystemMessage
        variant={LunaSystemMessageVariants.inline}
        type={LunaSystemMessageTypes.critical}
    >
        {message}</LunaSystemMessage> : null;
}

export default function PasswordConfirmationForm(props) {
    const [formData, setFormData] = useState({});
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const { email, firstName, lastName, processStatus } = props.candidate;
    const { srNum, SrNum } = queryString.parse(window.location.search);
    const querySrNum = srNum || SrNum;
    const disableButton = !(validatePassword(newPassword) && newPassword === confirmNewPassword);

    useEffect(() => {
        let timeout;
        let statusResponse = {};
        let data = {};

        const setProps = (responseError, loaderState, registrationState) => {
            clearTimeout(timeout);
            props.setResponseError(responseError);
            props.showLoader(loaderState);
            props.setRegistrationState(registrationState);
        }

        const getFetchTimer = () => setTimeout(async () => {
            await fetchData();
        }, TIME_BETWEEN_STATUS_CALLS);


        const fetchData = async () => {
            try {
                const isStatusInProgress = (processStatus === REGISTRATION_STATE.IN_PROGRESS || (statusResponse && statusResponse.status === REGISTRATION_STATE.IN_PROGRESS));

                props.setLoaderMessage(messages.LOADER_MESSAGE);
                props.showLoader(true);

                if (isStatusInProgress && statusRetries < MAX_STATUS_RETRIES) {
                    statusResponse = await fetchStatusLambda(querySrNum);
                    statusRetries++;
                    timeout = getFetchTimer();

                } else if (statusRetries >= MAX_STATUS_RETRIES) {
                    setProps('', false, REGISTRATION_STATE.INCOMPLETE)
                    throw Error(messages.STATUS_RETRIES_ERROR);

                } else {
                    data = await fetchRegisterLambda(formData);
                    statusResponse = await fetchStatusLambda(querySrNum);
                    timeout = getFetchTimer();
                }


                if (statusResponse.status === REGISTRATION_STATE.COMPLETE) {
                    setProps('', false, REGISTRATION_STATE.COMPLETE)
                    props.setCandidate({
                        "generatedEmail": statusResponse.email,
                        ...props.candidate
                    });
                }

                if (statusResponse.status === REGISTRATION_STATE.INCOMPLETE) {
                    setProps('', false, REGISTRATION_STATE.INCOMPLETE)
                
                }

                const { code, message } = data.status || {};
                if (code === RESPONSE_CODES.FAILURE) {
                    setProps(message, false, REGISTRATION_STATE.FAILED)
                }


            } catch (error) {
                setProps(messages.INVALID_INPUT_ERROR, false, REGISTRATION_STATE.INCOMPLETE)
            }
        }

        if (!isEmptyObject(formData) || (processStatus === REGISTRATION_STATE.IN_PROGRESS && statusRetries < MAX_STATUS_RETRIES)) {
            fetchData();
        }


    }, [formData, props, processStatus, querySrNum]);

    return (
        <LunaContainer
            theme={LunaContainerThemes.white}
            padding={[LunaContainerPaddings.l20.vertical, LunaContainerPaddings.l10.horizontal]}
            className="luna-container-card App-password-confirmation-card"
        >

            <h1 className="luna-type-header-60">{messages.PASSWORD_CONFIRMATION}</h1>

            <LunaSectionHeader
                divider={LunaSectionHeaderDividers.l20}
                margin={[LunaSectionHeaderMargins.l10.bottom]}
            >
                <LunaSectionHeaderTitle>
                    <h2 className="luna-type-header-50">{messages.CONTACT_INFORMATION}</h2>
                </LunaSectionHeaderTitle>
            </LunaSectionHeader>
            <p className="luna-type-body-30">
                {messages.HELLO} {firstName} {lastName}, <br />
                {messages.CONTACT_INFO_BODY_1} {email}.
            </p>
            <p className="luna-type-body-30">{messages.CONTACT_INFO_BODY_2}</p>


            <LunaSectionHeader
                divider={LunaSectionHeaderDividers.l20}
                margin={[LunaSectionHeaderMargins.l10.bottom]}
            >
                <LunaSectionHeaderTitle>
                    <h2 className="luna-type-header-50">{messages.LOGIN_INFORMATION}</h2>
                </LunaSectionHeaderTitle>
            </LunaSectionHeader>
            <p className="luna-type-body-30">{messages.LOGIN_INFO_BODY}</p>


            <LunaSectionHeader
                divider={LunaSectionHeaderDividers.l20}
                margin={[LunaSectionHeaderMargins.l10.bottom]}
            >
                <LunaSectionHeaderTitle>
                    <h2 className="luna-type-header-50">{messages.CREATE_PASSWORD}</h2>
                </LunaSectionHeaderTitle>
            </LunaSectionHeader>

            {renderInvalidInputMessage(props.responseError)}

            <LunaFormControlGroup
                label={messages.NEW_PASSWORD}
                helpMessage={messages.NEW_PASSWORD_HELP}
                systemMessage={messages.NEW_PASSWORD_ERROR}
                onChange={(event) => {
                    setNewPassword(event.target.value);
                }}
                validate={({ target }) => validatePassword(target.value)}
            >
                <LunaInput
                    name='ssr-new-password'
                    id='ssr-new-password'
                   /* other={{
                        maxLength: 64
                    }}*/
                    type="password"
                />
            </LunaFormControlGroup>


            <LunaFormControlGroup
                label={messages.CONFIRM_NEW_PASSWORD}
                systemMessage={messages.CONFIRM_NEW_PASSWORD_ERROR}
                validate={({ target }) => newPassword === target.value}
                onChange={(event) => {
                    setConfirmNewPassword(event.target.value);
                }}
            >
                <LunaInput
                    name='ssr-confirm-password'
                    id='ssr-confirm-password'
                    /*other={{
                        maxLength: 64
                    }}*/
                    type="password"
                />
            </LunaFormControlGroup>

            <LunaButton
                onClick={() => {
                    setFormData({
                        srNum: querySrNum,
                        newPassword,
                        confirmNewPassword
                    })
                }}

                disabled={disableButton}
            >{messages.SUBMIT}</LunaButton>

        </LunaContainer>
    )
}
