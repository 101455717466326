import axios from 'axios';
export const OPERATION_CANCELED = 'Operation canceled';

export async function fetchRegisterLambda(formData) {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();

    const commonHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
    const options = {
        url: '/api/register',
        method: 'POST',
        cancelToken: source.token,
        ...commonHeaders,
        data: formData
    }

    try {
        setTimeout(() => {
            //Lambda's timeout after 30 minutes, this is a work around for the persist workflow.
            source.cancel(OPERATION_CANCELED);
        }, 25 * 1000);

        const response = await axios(options)
        const { data } = response;
        return data;
    } catch (error) {
        if (axios.isCancel(error)) {
            return error;
        } else {
            throw Error('Fetch Register Error');
        }
    }
}


export async function fetchStatusLambda(srNum) {
    const commonHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
    const options = {
        url: '/api/status',
        method: 'GET',
        params: {
            srNum
        },
        ...commonHeaders
    }

    try {
        const response = await axios(options)
        const { data } = response;
        return data;

    } catch (error) {
        return;
    }
}