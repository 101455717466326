export const ROLE_PATH_NAMES = {
    FR: '/frRegistrationEmail.do',
    AFR: '/afrRegistrationEmail.do',
    STAFF: '/staffRegistrationEmail.do'
}


export function getRoleType() {
    const { pathname } = window.location;

    switch (pathname) {
        case ROLE_PATH_NAMES.FR:
            return "FR";
        case ROLE_PATH_NAMES.AFR:
            return "AFR";
        case ROLE_PATH_NAMES.STAFF:
            return "AFR";
        default:
            return;
    }

}