
import React from 'react';

import {
    LunaContainer, LunaContainerThemes, LunaContainerPaddings,
    LunaLoadingIndicator,
    LunaLoadingIndicatorSizes
} from './'

export default function Loader(props) {
    return (
        <LunaContainer
            theme={LunaContainerThemes.white}
            padding={[LunaContainerPaddings.l20.vertical, LunaContainerPaddings.l10.horizontal]}
            className="luna-container-card"
        >
            <div className="App-loader">
                <LunaLoadingIndicator size={LunaLoadingIndicatorSizes.size30} />
            </div>
            <p className="luna-type-body-30">
                {props.message}
            </p>
        </LunaContainer >
    )
}