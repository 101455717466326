
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import ReCAPTCHA from "react-google-recaptcha";

import {
    LunaContainer, LunaContainerThemes, LunaContainerPaddings, LunaSystemMessage,
    LunaSystemMessageVariants,
    LunaSystemMessageTypes,
    LunaFormControlGroup,
    LunaInput,
    LunaButton
} from '.'

import { validateEmail, getRoleType, isEmptyObject } from '../utils';
import { validateName } from '../utils';
import { fetchRegisterLambda } from '../services';
import { RESPONSE_CODES } from '../App';

const messages = {
    USER_REGISTRATION: 'User Registration',
    USER_REGISTRATION_BODY: 'Registering for a Northwestern Mutual online account will give you access to the Candidate Portal for completing the contracting process.',
    INFO_BANNER: 'Please use the same name and email already provided to Northwestern Mutual.',
    FIRST_NAME_INPUT: 'First Name',
    FIRST_NAME_INPUT_ERROR: 'Please enter a valid first name without any leading or trailing spaces.',
    LAST_NAME_INPUT: 'Last Name',
    LAST_NAME_INPUT_ERROR: 'Please enter a valid last name without any leading or trailing spaces.',
    EMAIL_ADDRESS_INPUT: 'Email Address',
    EMAIL_ADDRESS_INPUT_ERROR: 'Please enter a valid email address.',
    EMAIL_ADDRESS_INPUT_HELP: 'To confirm your identity, please enter your email address that you provided to the network office.',
    NEXT: 'Next',
    LOADER_MESSAGE: 'Verifying your identity. Please be patient and avoid refreshing the page or using your browser\'s back button.',
    MISSING_URL_PARAMS: 'Invalid URL used.',
    COME_VIA_EMAIL: 'Please come to this application via the link in the e-mail you received.',
    INVALID_INPUT_ERROR: "Please verify inputs",
    ALREADY_REGISTERED: "You are already registered for a Northwestern Mutual online account.",
    VALIDATION_FAILURE: "Registration could not be processed at this time. Please try again later."
}

const renderMissingParamsMessage = () => {
    return <LunaSystemMessage
        variant={LunaSystemMessageVariants.inline}
        type={LunaSystemMessageTypes.critical}
        headerText={messages.MISSING_URL_PARAMS}
    >
        {messages.COME_VIA_EMAIL}</LunaSystemMessage>;
}

const renderInvalidInputMessage = (message) => {
    return message ? <LunaSystemMessage
        variant={LunaSystemMessageVariants.inline}
        type={LunaSystemMessageTypes.critical}
    >
        {message}</LunaSystemMessage> : null;
}

export default function UserRegistrationForm(props) {
    const [formData, setFormData] = useState({});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [captcha, setCaptcha] = useState('');

    const { srNum, SrNum, roleType } = queryString.parse(window.location.search);
    const querySrNum = srNum || SrNum;

    const queryRole = roleType || getRoleType();
    const queryRoleType = queryRole === 'STAFF' ? 'AFR' : queryRole;
    const missingUrlParams = !(querySrNum && queryRoleType);
    const disableButton = !(validateName(firstName) && validateName(lastName) && validateEmail(emailAddress) && captcha && !missingUrlParams);

    useEffect(() => {

        const fetchData = async () => {
            try {
                props.setLoaderMessage(messages.LOADER_MESSAGE);
                props.showLoader(true);

                const data = await fetchRegisterLambda(formData);

                const { code, errorCode } = data.status || {};


                if (code === RESPONSE_CODES.FAILURE) {
                    if (errorCode === 501) {
                        props.setResponseError(messages.ALREADY_REGISTERED);

                    } else {
                        props.setResponseError(messages.VALIDATION_FAILURE);

                    }
                } else {
                    props.setCandidate(data);
                    props.setResponseError('');
                }

                props.showLoader(false);

            } catch (error) {
                props.showLoader(false);
                props.setResponseError(messages.INVALID_INPUT_ERROR)
            }
        }

        if (!isEmptyObject(formData)) {
            fetchData();
        }

    }, [formData, props]);

    return missingUrlParams ? renderMissingParamsMessage() : (
        <LunaContainer
            theme={LunaContainerThemes.white}
            padding={[LunaContainerPaddings.l20.vertical, LunaContainerPaddings.l10.horizontal]}
            className="luna-container-card ssr-user-registration-card"
        >

            <h1 className="luna-type-header-60">{messages.USER_REGISTRATION}</h1>
            <p className="luna-type-body-30">{messages.USER_REGISTRATION_BODY}</p>
            <LunaSystemMessage
                variant={LunaSystemMessageVariants.inline}
                type={LunaSystemMessageTypes.warning}
            >
                {messages.INFO_BANNER}</LunaSystemMessage>

            {renderInvalidInputMessage(props.responseError)}

            <LunaFormControlGroup
                label={messages.FIRST_NAME_INPUT}
                systemMessage={messages.FIRST_NAME_INPUT_ERROR}
                validate={({ target }) => validateName(target.value)}
            >
                <LunaInput
                    name='ssr-first-name'
                    id='ssr-first-name'
                    other={{
                        maxLength: 50
                    }}
                    onChange={({ target }) => setFirstName(target.value)}
                />
            </LunaFormControlGroup>


            <LunaFormControlGroup
                label={messages.LAST_NAME_INPUT}
                systemMessage={messages.LAST_NAME_INPUT_ERROR}
                validate={({ target }) => validateName(target.value)}
            >
                <LunaInput
                    name='ssr-last-name'
                    id='ssr-last-name'
                    other={{
                        maxLength: 50
                    }}
                    onChange={({ target }) => setLastName(target.value)}
                />
            </LunaFormControlGroup>


            <LunaFormControlGroup
                label={messages.EMAIL_ADDRESS_INPUT}
                systemMessage={messages.EMAIL_ADDRESS_INPUT_ERROR}
                utilityArea={messages.EMAIL_ADDRESS_INPUT_HELP}
                validate={({ target }) => validateEmail(target.value)}
            >
                <LunaInput
                    name='mfa-email-address'
                    id='mfa-email-address'
                    other={{
                        maxLength: 120
                    }}
                    onChange={({ target }) => setEmailAddress(target.value)}

                />
            </LunaFormControlGroup>

            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                onChange={(value) => {
                    setCaptcha(value)
                }}
            />

            <LunaButton onClick={() => {

                setFormData({
                    emailAddress,
                    firstName,
                    lastName,
                    srNum: querySrNum,
                    roleType: queryRoleType,
                    captcha
                })

            }}
                disabled={disableButton}
            >{messages.NEXT}</LunaButton>

        </LunaContainer >
    )
}
