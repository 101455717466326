const { REACT_APP_LAMBDA_PROXY_URL, REACT_APP_REGISTER_LAMBDA_PROXY_PATH, REACT_APP_STATUS_LAMBDA_PROXY_PATH } = process.env;

// If the proxy URLs aren't set via the env vars, then the default container name is used 
// (in compose you reference the network name directly) is used and the actual port
const lambdaProxyUrl = REACT_APP_LAMBDA_PROXY_URL || "use the .env and .env.offline to set this variable!!!"
const registerLambdaProxyPath = REACT_APP_REGISTER_LAMBDA_PROXY_PATH || "use the .env and .env.offline to set this variable!!!"
const statusLambdaProxyPath = REACT_APP_STATUS_LAMBDA_PROXY_PATH || "use the .env and .env.offline to set this variable!!!"


module.exports = { lambdaProxyUrl, registerLambdaProxyPath, statusLambdaProxyPath };
